import { environment } from 'src/environments/environment';

const convertToImageKitUrl = (
  imageUrl: string,
  height?: string | null,
  width?: string | null
) => {
  let imgKitBase = 'https://ik.imagekit.io/d3ddbxom7/';
  let flag = false;
  if (width || height) {
    imgKitBase = imgKitBase.concat('tr:');
    flag = true;
  }

  if (width) {
    imgKitBase = imgKitBase.concat('w-' + width);
  }
  if (height) {
    imgKitBase = imgKitBase.concat(',h-' + height);
  }

  if (flag) imgKitBase = imgKitBase.concat('/');

  imageUrl = imageUrl.replace(
    'https://firebasestorage.googleapis.com/v0/b/wink-309217.appspot.com/',
    imgKitBase
  );
  imageUrl = imageUrl.replace(
    `https://storage.googleapis.com/${environment.firebase.storageBucket}/`,
    imgKitBase
  );

  return imageUrl;
};

export default convertToImageKitUrl;
