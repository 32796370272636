<div
  [ngClass]="{
    'animate-cartClose': animateClose,
    'animate-cartOpen': !animateClose
  }"
  class="flex flex-col w-full overflow-y-auto max-w-xl real-h absolute top-0 right-0 z-50 bg-white !rounded-none !p-0"
>
  <!-- Cart title header -->
  <div class="flex justify-start items-center">
    <button
      class="bg-transparent text-red-500 text-2xl py-6 px-8"
      (click)="emptyCartConfirmBox()"
    >
      <i class="fas fa-trash-alt"></i>
    </button>
    <span class="text-2xl text-black font-bold">Carrito</span>
    <button
      (click)="closeBag()"
      class="bg-transparent ml-auto text-primary py-6 px-8 text-2xl"
    >
      <i class="fas fa-times"></i>
    </button>
  </div>

  <!-- Cart content -->
  <div
    class="flex flex-col h-full w-full overflow-y-auto items-start justify-start px-10 gap-6"
  >
    <!-- Empty cart -->
    <div
      class="flex flex-col justify-center items-center h-full w-full"
      *ngIf="shoppingBag?.orders?.length === 0; else cartItems"
    >
      <img
        src="assets/images/empty-cart.png"
        alt="Empty cart"
        class="h-62 w-auto"
      />
      <span class="text-slate-400">Tu carrito se encuentra vacío</span>
    </div>
    <!-- Cart items -->
    <ng-template #cartItems>
      <div class="flex w-full h-fit" *ngFor="let order of shoppingBag.orders">
        <!-- <img [src]="order.product.image_url[0]" [alt]="order.product.name" class="h-40 w-auto rounded-md mr-4"> -->
        <img
          *ngIf="order?.product?.image_url"
          data-src="{{
            convertToImageKitUrl(order.product.image_url[0], '160', '160')
          }}"
          [alt]="order.product.name"
          data-sizes="auto"
          [id]="order.product._id"
          [ngClass]="{ lazyload: true, show: image.complete }"
          #image
          class="w-[100px] h-auto object-contain lazyload rounded-md mr-4"
        />
        <div class="flex flex-col min-w-[calc(100%-10rem)] h-full">
          <span class="text-black font-bold text-base truncate my-2">{{
            order.product.name
          }}</span>
          <span
            class="text-gray-400 text-xs truncate"
            *ngIf="order.OrderVariants.length > 0"
            >{{ order.OrderVariants[0].raw_value }}</span
          >
          <div class="flex items-center flex-wrap">
            <span
              class="text-gray-400 text-base"
              *ngFor="let extra of order.extras"
            >
              {{ extra.name }},
            </span>
          </div>
          <span class="text-primary font-bold text-base my-2">{{
            order.price * order.quantity | customCurrency
          }}</span>
          <div class="flex items-center w-full mt-auto">
            <button
              (click)="remove(order)"
              [ngClass]="{
                'bg-black': order.quantity > 1,
                'bg-gray-300': order.quantity === 1,
                'text-white': order.quantity > 1,
                'text-red-500': order.quantity === 1
              }"
              class="rounded-full h-7 w-7"
            >
              <i
                [ngClass]="{
                  'fa-minus': order.quantity > 1,
                  'fa-trash-alt': order.quantity === 1
                }"
                class="fas fa-minus text-[14px]"
              ></i>
            </button>
            <span class="text-base text-black px-8">{{ order.quantity }}</span>
            <button
              class="rounded-full bg-black text-white h-7 w-7"
              (click)="add(order)"
            >
              <i class="fas fa-plus text-[14px]"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="h-fit w-full p-4">
    <div class="h-full w-full border-t border-gray-300 flex flex-col">
      <div
        class="w-full px-4 py-2 flex gap-4 items-center bg-gray-100 rounded-md mt-2 justify-center"
        *ngIf="settings.averageDeliveryTime > 0"
      >
        <i class="far fa-clock text-[#0D88FB] text-[14px]"></i>
        <span class="text-[14px] md:text-base text-gray-600"
          >Tiempo estimado de entrega:
          {{ settings.averageDeliveryTime }}
          {{
            settings.averageDeliveryUnit === "hour" ? "horas" : "minutos"
          }}</span
        >
      </div>
      <div class="flex justify-between items-center my-4">
        <span class="text-xl text-black font-bold">Subtotal</span>
        <span class="text-xl text-black font-bold">{{
          getSubtotal() | customCurrency
        }}</span>
      </div>
      <div class="flex items-center justify-center gap-4">
        <button
          class="bg-primary text-white rounded-md py-2 w-[45%] font-bold disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed flex item-center justify-center flex-1"
          [disabled]="shoppingBag?.orders?.length === 0 || loading"
          (click)="processCart(false)"
        >
          <span
            ><i class="fa fa-shopping-bag" aria-hidden="true"></i> Pickup</span
          >
        </button>
        <button
          class="bg-primary text-white rounded-md py-2 w-[45%] font-bold disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed flex item-center justify-center flex-1"
          [disabled]="shoppingBag?.orders?.length === 0 || loading"
          (click)="processCart(true)"
        >
          <span><i class="fa fa-truck" aria-hidden="true"></i> Delivery</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  (click)="closeBag()"
  [ngClass]="{
    'animate-overlayClose': animateClose,
    'animate-overlay': !animateClose
  }"
  class="h-[100vh] w-full absolute z-10 bg-black opacity-40 top-0"
></div>
