import { Pipe, PipeTransform } from '@angular/core';
import { LocalService } from '../services/local.service';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private local: LocalService) {}

  transform(value: number): string {
    if (value === null) {
      return '';
    }
    const currency = this.local.getValue('currency');
    const customSymbol = currency.symbol;
    const currencyLang = currency.lang;
    const formatter = new Intl.NumberFormat(currencyLang, {
      style: 'currency',
      currency: currency.code,
      currencyDisplay: 'symbol',
    });

    let formattedValue = formatter.format(value);
    if (currency.code === 'VES') {
      formattedValue = formattedValue.replace('Bs.S', 'Bs');
    }

    const defaultSymbol = formattedValue.replace(/[0-9.,\s]+/g, '').trim();

    if (customSymbol && customSymbol !== defaultSymbol) {
      formattedValue = formattedValue.replace(defaultSymbol, customSymbol);
    }
    return formattedValue;
  }
}
