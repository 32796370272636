import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Category } from 'src/app/models/category';
import { Product } from 'src/app/models/product';
import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-featured-category-carousel',
  templateUrl: './featured-category-carousel.component.html',
  styleUrls: ['./featured-category-carousel.component.scss'],
})
export class FeaturedCategoryCarouselComponent implements OnInit, OnDestroy {
  isDesktop: boolean = false;
  category = this.api.featuredCategory;
  loading = true;
  categoryProducts: Product[] = [];
  featuredSubscription!: Subscription;
  config: SwiperOptions = {
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    cssMode: true,
    spaceBetween: 20,
    slidesPerView: 2,
    autoplay: true,
    speed: 200,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
      1440: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
    },
    injectStyles: [
      `
      .swiper-button-next, .swiper-button-prev {
        color: var(--primary-color);
      }
      .swiper-wrapper{
        padding-bottom: 20px
      }
      .swiper-pagination {
        position: absolute !important;
        bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        .swiper-pagination-bullet-active {
          background-color: var(--primary-color);
          width: 30px !important;
          border-radius: 15px;
        }
        .swiper-pagination-bullet {
           height: 10px !important;
          width: 10px;
          transition: all ease-in 200ms;
          }
        }
        `,
    ],
  };
  constructor(
    private api: ApiService,
    private local: LocalService,
    private notyf: NotyfService,
    private bp: BreakpointObserver
  ) {
    this.bp
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktop = state.matches;
      });
  }
  ngOnInit(): void {
    this.featuredSubscription = this.api.featuredCategorySubject.subscribe(async () => {
      this.loadAsyncData();
    });
    if (Object.keys(this.api.featuredCategory).length > 0) {
      this.loadAsyncData();
    }

    // this.loadAsyncData();
  }

  ngOnDestroy() {
    this.featuredSubscription.unsubscribe();
  }
  async loadAsyncData() {
    try {
      this.category = this.api.featuredCategory;
      const response = await this.api.getProducts(
        8,
        0,
        this.local.getValue('inventory').id,
        'cat',
        'default',
        this.category._id
      );
      this.categoryProducts = response.data;
      this.categoryProducts = this.categoryProducts.filter((p) => p.active || p.show_in_store);
      this.loading = false;
    } catch (error) {
      this.notyf.error(error);
      this.loading = false;
    }
  }
}
