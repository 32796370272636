<div class="page !overflow-y-auto bg-white">
  <div class="w-full h-full max-h-[145px] md:max-h-[236px] bg-gray-200">
    <img
      *ngIf="design.background_url"
      data-src="{{ convertToImageKitUrl(design.background_url, null, '1000') }}"
      alt="Banner image"
      data-sizes="auto"
      id="banner-image"
      [ngClass]="{
        lazyload: true,
        show: image.complete,
        'animate-pulse': !image.complete
      }"
      #image
      class="w-full max-h-[145px] md:max-h-[236px] object-cover brightness-50 lazyload"
    />
  </div>
  <div class="md:container md:mx-auto md:py-10">
    <div class="flex flex-col md:flex-row gap-8 pb-[100px]">
      <div class="md:basis-1/2">
        <google-map
          width="100%"
          height="400px"
          [center]="center"
          [options]="mapOptions"
          (mapInitialized)="locateMarker()"
        >
          <map-marker
            [position]="center"
            [options]="markerOptions"
          ></map-marker>
        </google-map>
      </div>
      <div class="md:basis-1/2 px-4">
        <h2 class="text-lg font-bold mb-6">{{ inventory.name }}</h2>
        <div class="mb-6">
          <h4 class="font-bold mb-3">Dirección:</h4>
          <p>{{ inventory.address }}</p>
        </div>
        <div>
          <h4 class="font-bold mb-3">Horarios:</h4>
          <div class="flex flex-col gap-3 w-full">
            <div
              *ngFor="let timeTable of timeTables"
              class="flex flex-col w-full"
            >
              <div
                class="flex justify-between w-full"
                *ngFor="let schedule of timeTable.schedules"
                [ngClass]="{ 'font-bold': currentDay === schedule.day }"
              >
                <span>{{ transformDay(schedule.day) }}</span>
                <span *ngIf="timeTable.open; else elseBlock">
                  {{ transformHour(schedule.openTime) }} -
                  {{ transformHour(schedule.closeTime) }}
                </span>
                <ng-template #elseBlock>
                  <span>Cerrado</span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
