<div class="page !overflow-y-auto bg-white pb-[100px]">
  <!-- Schedule Alert -->
  <app-schedule-alert></app-schedule-alert>

  <!-- Banner image -->
  <div class="w-full h-full max-h-[145px] md:max-h-[236px] bg-gray-200">
    <ng-container *ngIf="design">
      <img
        *ngIf="
          design?.background_url !== '' &&
          design?.background_url !== null &&
          design?.background_url !== undefined
        "
        data-src="{{
          convertToImageKitUrl(
            design.background_url,
            null,
            windowWidth.toString()
          )
        }}"
        alt="Banner image"
        data-sizes="auto"
        id="banner-image"
        [ngClass]="{
          lazyload: true,
          show: image.complete,
          'animate-pulse': !image.complete
        }"
        #image
        class="w-full max-h-[145px] md:max-h-[236px] object-cover lazyload brightness-[.9]"
      />
    </ng-container>
  </div>

  <!-- Grid title header -->
  <div class="flex items-center justify-between px-6 md:px-12 lg:px-32 py-8">
    <span class="text-xl font-bold text-black">Productos destacados</span>
    <a
      [routerLink]="['/productos']"
      class="rounded-md bg-transparent text-black font-bold text-sm py-2 px-4 border-2 border-black"
      >Ver todos</a
    >
  </div>

  <!-- Featured products grid -->
  <div
    *ngIf="!loading"
    class="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10 justify-center px-6 md:px-12 lg:px-32"
  >
    <app-product-card
      *ngFor="let product of products"
      [product]="product"
      class="w-full"
    ></app-product-card>
  </div>
  <!-- Featured category carousel -->
  <div class="w-full px-6 md:px-12 lg:px-32" *ngIf="!loading">
    <app-featured-category-carousel></app-featured-category-carousel>
  </div>
  <!-- Button see all -->
  <div
    *ngIf="!loading && !isDesktop"
    class="flex justify-center items-center w-full pt-16"
  >
    <a
      class="rounded-md bg-primary text-white font-bold text-sm py-2 px-4 border border-primary"
      [routerLink]="['/productos']"
      [queryParams]="{ cid: '', sort: 'default' }"
    >
      <span class="text-base">Ver todos</span>
    </a>
  </div>

  <!-- Product grid skeleton loading -->
  <div
    *ngIf="loading"
    class="pw-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10 justify-center px-6 md:px-12 lg:px-32"
  >
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
    <div
      class="animate-pulse w-full h-[300px] md:h-[342px] rounded-md bg-gray-200"
    ></div>
  </div>
</div>
