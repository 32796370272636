<div
  class="z-30 flex flex-col w-full h-[75px] lg:h-[100px] relative"
  (mouseleave)="dropdownOpen = false"
>
  <div
    class="flex w-full items-center bg-secondary justify-between h-[75px] lg:h-[100px]"
  >
    <div class="flex items-center">
      <button
        (click)="menuOpen = true"
        class="bg-transparent lg:hidden text-black px-6 py-3"
      >
        <i class="fas fa-bars"></i>
      </button>
      <a [routerLink]="['/']">
        <!-- <img [src]="design!.logo_url" alt="Logo" class="h-[80px] max-w-[150px] object-contain md:mx-4 lazyload" /> -->
        <ng-container *ngIf="design">
          <img
            *ngIf="design.logo_url"
            data-src="{{ convertToImageKitUrl(design.logo_url, '200') }}"
            [alt]="'Logo de tienda'"
            data-sizes="auto"
            id="header-logo"
            [ngClass]="{ lazyload: true, show: image.complete }"
            #image
            class="!h-[70px] max-w-[150px] object-contain md:mx-4"
          />
        </ng-container>
      </a>
      <a
        [routerLink]="['/']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="!font-bold !text-black"
        class="text-black text-lg mx-4 hidden lg:block"
        >Inicio</a
      >
      <a
        *ngIf="!business.navigationActive"
        (mouseover)="dropdownOpen = true"
        [routerLink]="['/productos']"
        routerLinkActive="!font-bold !text-black"
        class="peer text-black text-lg mx-4 hidden lg:block"
      >
        <span>Productos</span>
        <i class="fas fa-chevron-down ml-2"></i>
      </a>
      <ng-container *ngIf="business.navigationActive">
        <ng-container *ngFor="let route of routes">
          <ng-container *ngIf="route.items.length > 0; else singleNavigation">
            <a
              *ngIf="route.type === 'CATEGORY'; else external"
              (mouseover)="dropdownOpen = true; hoveredNavigation = route"
              [routerLink]="['/productos']"
              [queryParams]="{ cid: route.link }"
              routerLinkActive="!font-bold !text-black"
              class="peer text-black text-lg mx-4 hidden lg:block"
            >
              <span>{{ route.name }}</span>
              <i class="fas fa-chevron-down ml-2"></i>
            </a>
            <ng-template #external>
              <a
                (mouseover)="dropdownOpen = true; hoveredNavigation = route"
                [href]="route.link"
                class="peer text-black text-lg mx-4 hidden lg:block"
              >
                <span>{{ route.name }}</span>
                <i class="fas fa-chevron-down ml-2"></i>
              </a>
            </ng-template>
          </ng-container>

          <ng-template #singleNavigation>
            <a
              *ngIf="route.type === 'CATEGORY'; else singleExternal"
              [routerLink]="['/productos']"
              [queryParams]="{ cid: route.link }"
              routerLinkActive="!font-bold !text-black"
              class="peer text-black text-lg mx-4 hidden lg:block"
            >
              <span>{{ route.name }}</span>
            </a>
            <ng-template #singleExternal>
              <a
                [href]="route.link"
                class="peer text-black text-lg mx-4 hidden lg:block"
              >
                <span>{{ route.name }}</span>
              </a>
            </ng-template>
          </ng-template>
        </ng-container>
      </ng-container>
      <a
        [routerLink]="['/contacto']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="!font-bold !text-black"
        class="text-black text-lg mx-4 hidden lg:block"
        >Contacto</a
      >
    </div>

    <div class="flex items-center">
      <div class="select-wrapper hidden md:block w-auto">
        <select
          name="selectedInventory"
          (ngModelChange)="inventoryChange($event)"
          class="w-full border border-black !pr-8"
          [(ngModel)]="selectedInventory"
        >
          <option *ngFor="let inventory of inventories" [ngValue]="inventory">
            📍 {{ inventory.name }}
          </option>
        </select>
      </div>
      <button
        class="bg-transparent relative text-black px-6 py-3"
        (click)="searchOpen = true"
      >
        <i class="fas fa-search text-lg"></i>
      </button>
      <button
        class="bg-transparent relative text-black px-6 py-3 ml-auto"
        (click)="bagOpen = true"
      >
        <i class="fas fa-shopping-cart text-lg"></i>
        <span class="flex h-5 w-5 absolute top-[15%] right-[15%]">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"
            *ngIf="quantity > 0"
          ></span>

          <span
            *ngIf="quantity > 0"
            class="relative inline-flex items-center justify-center text-white rounded-full h-full w-full bg-primary text-sm"
          >
            {{ quantity }}
          </span>
        </span>
      </button>
    </div>
  </div>
  <div
    (mouseover)="dropdownOpen = true"
    class="animate-fadeIn w-full flex bg-white drop-shadow-lg flex-wrap p-8 gap-10 absolute top-[100px]"
    [ngClass]="{ hidden: !dropdownOpen, flex: dropdownOpen }"
  >
    <ng-container *ngIf="!business.navigationActive; else customNavigation">
      <a
        class="text-black text-lg font-bold"
        [routerLink]="['/productos']"
        (click)="dropdownOpen = false"
        >Todos</a
      >
      <a
        class="text-black text-lg font-bold"
        (click)="dropdownOpen = false"
        *ngFor="let category of categories"
        [routerLink]="['/productos']"
        [queryParams]="{ cid: category._id }"
        >{{ category.name | titlecase}}</a
      >
    </ng-container>
    <ng-template #customNavigation>
      <ng-container *ngIf="hoveredNavigation">
        <app-custom-navigation
          class="w-full"
          [items]="hoveredNavigation.items"
        ></app-custom-navigation>
      </ng-container>
    </ng-template>
  </div>
</div>

<app-side-menu
  *ngIf="menuOpen"
  [categories]="categories"
  [contact]="contact"
  [business]="business"
  [routes]="routes"
  (closeEmit)="menuOpen = false"
></app-side-menu>
<app-shopping-bag
  *ngIf="bagOpen"
  (closeEmit)="closeBag.emit(); bagOpen = false"
></app-shopping-bag>
<app-search *ngIf="searchOpen" (closeEmit)="searchOpen = false"></app-search>
