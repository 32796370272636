import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { Category } from 'src/app/models/category';

import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { ThemeService } from 'src/app/services/theme.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NotyfService } from 'src/app/services/notyf.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  math = Math;
  menuOpen = false;
  categories: Category[] = [];
  products: Product[] = [];
  productsCount: number = 0;
  fetching: boolean = true;
  isDesktop = true;
  page: number = 0;
  qtyItems = 8;
  loading = true;
  categoryFilter: string = '';
  sortingFilter: string = '';
  filter: string = '';
  loadingMoreProducts = false;
  last: number = 0;
  first: number = 1;
  second: number = 0;
  constructor(
    private theme: ThemeService,
    private api: ApiService,
    private local: LocalService,
    private bp: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private notyf: NotyfService
  ) {}

  ngOnInit(): void {
    this.bp
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktop = state.matches;
        this.loadAsyncData();
      });
  }

  async loadAsyncData() {
    try {
      this.loading = true;
      this.categories = await this.api.getCategories(
        this.local.getValue('inventory').id
      );
      if (Array.isArray(this.categories)) {
        this.subscribeToRoute();
      } else {
        throw 'No se encontraron categorías.';
      }
    } catch (error) {
      this.notyf.error(error, error);
      this.loading = false;
    }
  }
  async loadMoreProducts() {
    try {
      this.loadingMoreProducts = true;
      this.page++;
      const response = await this.api.getProducts(
        this.qtyItems,
        this.page,
        this.local.getValue('inventory').id,
        this.filter,
        this.sortingFilter,
        this.categoryFilter
      );
      this.productsCount = response.count;
      this.products = this.products.concat(response.data);
      this.products = this.products.filter((p) => p.active || p.show_in_store);
      this.loadingMoreProducts = false;
    } catch (error) {
      this.loadingMoreProducts = false;
      this.notyf.error(error);
    }
  }
  async loadPaginatedProducts() {
    try {
      this.loading = true;
      const response = await this.api.getProducts(
        this.qtyItems,
        this.page,
        this.local.getValue('inventory').id,
        this.filter,
        this.sortingFilter,
        this.categoryFilter
      );
      this.productsCount = response.count;
      this.products = response.data;
      this.products = this.products.filter((p) => p.active || p.show_in_store);
      if (Array.isArray(this.products)) {
        this.first = this.page === 0 ? 1 : this.page * this.qtyItems + 1;
        this.second =
          response.data.length !== this.qtyItems
            ? this.productsCount
            : response.data.length * (this.page + 1);

        this.loading = false;
      } else {
        throw 'No se encontraron productos.';
      }
    } catch (error) {
      this.loading = false;
      this.notyf.error(error, error);
    }
  }

  getCategoryName(id: string) {
    let cat = {} as Category;
    this.categories.forEach((category) => {
      if (category._id === id) {
        cat = category;
      }
    });
    return cat.name;
  }

  subscribeToRoute() {
    this.route.queryParams.subscribe(async (params) => {
      this.sortingFilter = 'default';
      this.categoryFilter = '';
      this.filter = 'allMobile';
      this.page = 0;
      if (
        params['cid'] != '' &&
        params['cid'] != null &&
        params['cid'] != undefined
      ) {
        this.filter = 'cat';
        this.categoryFilter = params['cid'];
      }
      if (
        params['sort'] != '' &&
        params['sort'] != null &&
        params['sort'] != undefined
      ) {
        this.sortingFilter = params['sort'];
      }
      if (
        params['page'] != '' &&
        params['page'] != null &&
        params['page'] != undefined
      ) {
        this.page = parseInt(params['page']);
        if (!this.isDesktop) this.page = 0;
      }
      this.loadPaginatedProducts();
      this.last = 0;
      this.first = 1;
      this.second = 0;
    });
  }
  async onScroll(event: any) {
    if (
      !this.isDesktop &&
      event.target.offsetHeight + event.target.scrollTop + 200 >=
        event.target.scrollHeight &&
      this.products.length < this.productsCount &&
      !this.loadingMoreProducts
    ) {
      await this.loadMoreProducts();
    }
  }
  changePage(page: number, plus: boolean) {
    this.page = page;
    this.router.navigate([], {
      queryParams: { page: this.page },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
    if (plus) {
      this.first = this.first + this.last;
    } else {
      this.first = this.first - 8;
    }
  }
}
