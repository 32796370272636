<div class="layout">
    <app-header *ngIf="!loading" class="fixed w-full top-0 z-50" [business]="business" [routes]="navigation ? navigation.routes : [] " [bagOpen]="bagOpen" (closeBag)="closeShoppingBag()"
        [contact]="contact"></app-header>
    <div class="page relative">
        <router-outlet *ngIf="!loading"></router-outlet>
        <a *ngIf="!loading && (!hideButtons || isDesktop)" [href]="'https://wa.me/' + contact.phone_number"
            aria-label="Send whatsapp message" target="_blank"
            class="button circle--icon bg-primary shadow-lg !h-[40px] !w-[40px] absolute bottom-[20px] left-[10px] z-10">
            <i class="fa-brands fa-whatsapp"></i>
        </a>
        <button (click)="bagOpen = true" *ngIf="shoppingBag.orders.length > 0 && (!hideButtons || isDesktop)"
            aria-label="ir al carrito"
            class="button !w-[250px] bg-primary shadow-lg !text-white !h-[40px] flex flex-row items-center justify-center gap-5 absolute bottom-[20px] right-[10px] z-20">
            <span class="text-base">Ir al carrito</span>
            <span class="text-base flex items-center gap-5">
                {{getShoppingBagTotal() | customCurrency}}
                <i class="fa-solid fa-chevron-right  text-base"></i>
            </span>
        </button>
    </div>
    <app-footer class="fixed w-full bottom-0 hidden lg:flex"></app-footer>
</div>