<div
  [ngClass]="{
    'animate-cartClose': animateClose,
    'animate-cartOpen': !animateClose,
  }"
  class="flex flex-col w-full max-w-xl overflow-y-auto real-h absolute top-0 right-0 z-50 bg-white !rounded-none !p-0"
>
  <!-- Search title header -->
  <div class="flex justify-between items-center py-6" (click)="closeSearch()">
    <span class="text-2xl text-black font-bold pl-8">Búsqueda</span>
    <button class="bg-transparent text-primary text-2xl pr-8">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <!-- Search input -->
  <div class="flex w-full h-[50px] px-8">
    <div class="search-wrapper h-full">
      <input
        autofocus
        #search
        type="text"
        class="input !rounded-r-none !pl-10 w-full h-full"
        (input)="doSearch(search.value)"
      />
    </div>
    <button
      class="!rounded-l-none border-b-2 border-solid border-secondary h-full !w-[60px]"
      (click)="clearSearch()"
    >
      <i class="fa-solid fa-times"></i>
    </button>
  </div>
  <!-- Search results -->
  <div class="flex flex-col w-full gap-5 h-full overflow-scroll">
    <div class="w-full" *ngIf="!searching; else loading">
      <ng-container *ngFor="let product of products">
        <app-product-item
          [product]="product"
          (click)="closeSearch()"
        ></app-product-item>
      </ng-container>
    </div>
    <ng-template #loading>
      <div class="flex items-center justify-center flex-col h-full w-full">
        <div class="h-28 w-28">
          <app-spinner [color]="'primary'"></app-spinner>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  (click)="closeSearch()"
  [ngClass]="{
    'animate-overlayClose': animateClose,
    'animate-overlay': !animateClose,
  }"
  class="h-[100vh] w-full absolute z-10 bg-black opacity-40 top-0"
></div>
