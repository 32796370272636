import { Category } from "../models/category";
import { OrderExtra } from "../models/order-extra";
import { Product } from "../models/product";

const getProductsOfCategory = (category: Category, products: Product[]): Product[] => {
    const filteredProducts: Product[] = products.filter((product) => product.category_ref === category._id);
    return filteredProducts;
}

const getPaginatedProducts = (page: number | undefined, products: Product[] | undefined, size: number): Product[] => {
    if(page && products) {
        return products.slice((page - 1) * size, page * size);
    } else {
        return [];
    }
}

const areExtrasEqual = (extras1: OrderExtra[], extras2: OrderExtra[]): boolean => {
    if (extras1.length !== extras2.length) return false;
  
  return extras1.every(extra1 => 
    extras2.some(extra2 => 
      JSON.stringify(extra1.ref_extra) === JSON.stringify(extra2.ref_extra)
    )
  );
}

export { getProductsOfCategory, getPaginatedProducts, areExtrasEqual };
