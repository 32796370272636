import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Design } from 'src/app/models/design';
import { LocalService } from 'src/app/services/local.service';
import { ThemeService } from 'src/app/services/theme.service';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { ApiService } from 'src/app/services/api.service';
import { Category } from 'src/app/models/category';
import { SupportContact } from 'src/app/models/support-contact';
import { Subscription } from 'rxjs';
import minutesBetweenDates from 'src/app/utils/minutesBetweenDates';
import { ShoppingBag } from 'src/app/models/shopping-bag';
import { NotyfService } from 'src/app/services/notyf.service';
import { Inventory } from 'src/app/models/inventory';
import { Business } from 'src/app/models/business';
import { RouteItem } from 'src/app/models/route';
import { Router } from '@angular/router';
import convertToImageKitUrl from 'src/app/utils/imagekit';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  design!: Design;
  isDesktop!: boolean;
  convertToImageKitUrl = convertToImageKitUrl;
  menuOpen = false;
  @Input() bagOpen!: boolean;
  @Input() business!: Business;
  @Output() closeBag = new EventEmitter<any>();
  searchOpen = false;
  quantity = 0;
  categories: Category[] = [];
  @Input() contact!: SupportContact;
  quantitySubscription!: Subscription;
  dropdownOpen = false;
  inventories = [] as Inventory[];
  selectedInventory = {} as Inventory;
  shoppingBag!: ShoppingBag;
  hoveredNavigation!: RouteItem;
  @Input() routes!: RouteItem[];
  constructor(
    private theme: ThemeService,
    private bp: BreakpointObserver,
    private router: Router,
    private api: ApiService,
    private local: LocalService,
    private notyf: NotyfService
  ) {}

  ngOnInit() {
    document
      .getElementById('header-logo')
      ?.addEventListener('complete', () => {});
    this.design = this.theme.getCachedTheme();
    this.bp.observe([Breakpoints.Small]).subscribe((state: BreakpointState) => {
      this.isDesktop = state.matches;
      if (this.isDesktop && this.menuOpen) {
        this.menuOpen = false;
      }
    });
    this.quantitySubscription = this.local.quantitySubject.subscribe(
      (quantity) => {
        this.quantity = quantity;
      }
    );
    this.loadLocalData();
    this.loadAsyncData();
  }
  ngAfterViewInit() {
    document.querySelector('img')?.addEventListener('complete', () => {});
  }

  loadLocalData() {
    if (
      minutesBetweenDates(
        this.local.getValue('shoppingBag').timestamp,
        new Date().getTime()
      ) > 120
    ) {
      const shoppingBag = {
        orders: [],
        timestamp: new Date().getTime(),
      };
      this.local.setValue('shoppingBag', shoppingBag);
      // Update items quantity
      this.local.updateQuantitySubject();
    }
    const bag = this.local.getValue('shoppingBag') as ShoppingBag;
    bag.orders.forEach((order) => {
      this.quantity = this.quantity + order.quantity;
    });
    this.inventories = this.local.getValue('inventories');
    this.selectedInventory = this.inventories.filter(
      (inv) => inv.id === this.local.getValue('inventory').id
    )[0];
  }

  async loadAsyncData() {
    try {
      this.categories = await this.api.getCategories(
        this.local.getValue('inventory').id
      );
      this.api.featuredCategory = this.categories[0];
      this.api.featuredCategorySubject.next();
      this.contact = await this.api.getSupportContactOfInventory(
        this.local.getValue('inventory').id
      );
    } catch (error) {
      this.notyf.error(error);
    }
  }

  ngOnDestroy(): void {
    this.quantitySubscription.unsubscribe();
  }
  inventoryChange(inventory: Inventory) {
    this.local.setValue('inventory', {
      ...inventory,
      timestamp: Date.now(),
    });
    this.shoppingBag = this.local.getValue('shoppingBag') as ShoppingBag;
    if (this.shoppingBag.orders.length) this.emptyCart();
    window.location.reload();
  }

  navigateRoute(route: RouteItem) {
    if (route.type === 'CATEGORY') {
      this.router.navigate(['/productos'], {
        queryParams: { cid: route.link },
      });
    } else {
      window.location.href = route.link;
    }
  }

  emptyCart() {
    this.shoppingBag.orders = [];
    this.local.setValue('shoppingBag', this.shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }
}
