import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Design } from 'src/app/models/design';
import { Product } from 'src/app/models/product';
import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';
import { ThemeService } from 'src/app/services/theme.service';
import convertToImageKitUrl from 'src/app/utils/imagekit';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  convertToImageKitUrl = convertToImageKitUrl;
  design = {
    background_url: '',
    _id: '',
    logo_url: '',
    color_palette: [],
    font: '',
    product_template_id: 0,
    header_visible: false,
    car_icon_id: 0,
    categories_template_id: 0,
    product_card_id: 0,
  } as Design | undefined;
  loading = true;
  products: Product[] = [];
  isDesktop = false;
  windowWidth!: number;
  constructor(
    private theme: ThemeService,
    private api: ApiService,
    private local: LocalService,
    private notyf: NotyfService,
    private bp: BreakpointObserver
  ) {
    this.bp
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktop = state.matches;
      });
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    document
      .getElementById('banner-image')
      ?.addEventListener('complete', () => {});
    this.loadLocalData();
    this.loadAsyncData();
  }

  async loadAsyncData() {
    try {
      const response = await this.api.getProducts(
        8,
        0,
        this.local.getValue('inventory').id,
        'allMobile',
        'default'
      );
      this.products = response.data;
      this.products = this.products.filter((p) => p.active || p.show_in_store);
      this.loading = false;
    } catch (error) {
      this.notyf.error(error);
      this.loading = false;
    }
  }

  loadLocalData() {
    this.design = this.theme.getCachedTheme();
  }
}
